
  import { Component, Prop } from 'vue-property-decorator'
  import { Field } from './field'

@Component
  export default class LinkedAuto extends Field {
  @Prop({ type: Object }) readonly properties!: any;
  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>;
  @Prop({ type: String }) type!: string
  @Prop({ type: Boolean, default: true }) creatable!: boolean
  @Prop({ type: Boolean, default: false }) disabledEdit!: boolean

  valid = false
  $refs!: {
    form: HTMLFormElement
  };

  get icon () {
    const { value } = this

    return value?.id ? 'mdi-pencil' : 'mdi-plus-circle-outline'
  }

  get bind () {
    const { properties, items, disabled, required, fieldRequired } = this

    return {
      ...properties,
      disabled,
      required,
      rules: required ? [...fieldRequired] : [],
      items,
      clearable: !disabled,
      name: properties?.name,
    }
  }

  mounted () {
    if (this.$refs?.form?.resetValidation) this.$refs.form?.resetValidation()
  }
  }
